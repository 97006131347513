<template>
  <div class="row">
    <!-- <div class="toggle" @click="toggle">TOGGLE</div> -->

    <AudioRecorder v-if="showRecorder"
      upload-url="http://localhost:3003/v1.0/upload/audio-upload-s3"
      filename="data.label"
      format="wav"
      :attempts="3"
      :time="2"
      :headers="headers"
      :successful-upload="uploadsuccessful"
      :failed-upload="callback"
      :bit-rate="192"/>

    <AudioPlayer :src="mp3" v-if="!showRecorder"/>
  </div>
</template>

<script>
import AudioPlayer   from './components/player.vue'
import AudioRecorder from './components/recorder.vue'
  export default {
    name: 'app',
    props: [
      "data",
    "form"],
     components:{
        AudioPlayer,
        AudioRecorder
    },
    mounted(){
    },
    data () {
      return {
        mp3: '/demo/example.mp3',
        showRecorder: true,
        headers: {
          'X-Custom-Header': 'some data'
        }
      }
    },
    methods: {
      callback (msg) {
        console.log('Event: ', msg)
      },
      toggle () {
        this.showRecorder = !this.showRecorder

      },
      uploadsuccessful(e){
        this.$message({
        message: "Audio Fileuploaded successfully!",
        type: "success",
      });
      console.log("e",e)
      }
    }
  }
</script>
<style lang="scss">
  .toggle {
    cursor: pointer;
    margin: 20px;
  }
</style>