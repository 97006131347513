<template>
  <div :style="getElementStyle">
    <!-- Below code is for Before Field View -->
    <div v-if="!isDefalutPos">
      <div style="display: flex; align-items: center">
        <label for="text" style="margin-right: 5px; white-space: nowrap"
          >{{ data.label }}
        </label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span v-if="data.description" style="margin-right: 5px">
          <i class="el-icon-info" :title="data.description"></i>
        </span>

        <AudioTool
          upload-url="some url"
          filename="ninja"
          format="wav"
          :attempts="3"
          :time="2"
          :headers="headers"
          :before-recording="callback"
          :pause-recording="callback"
          :after-recording="callback"
          :select-record="callback"
          :before-upload="callback"
          :successful-upload="callback"
          :failed-upload="callback"
          :bit-rate="192"
        ></AudioTool>
      </div>
      <div>
        <span v-if="isActive || isDataTableField" class="setting-icon">
          <i class="el-icon-s-tools" @click="openSettings"></i>
        </span>
      </div>
    </div>

    <!-- Below Code is for Top Field View -->

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <label for="text">{{ data.label }} </label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
          <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>

      <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
          <span v-if="data.description">
            <i
              class="el-icon-info"
              :title="data.description"
            ></i>
          </span>
        </el-col> -->
      <el-row v-if="isActive || isDataTableField" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
      </el-row>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <AudioTool
          upload-url="some url"
          filename="ninja"
          format="wav"
          :attempts="3"
          :time="2"
          :headers="headers"
          :successful-upload="callback"
          :failed-upload="callback"
          :bit-rate="192"
        ></AudioTool>
      </el-col>
    </el-row>

  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import AudioTool from "../AudioTool/audiotool.vue";
export default {
  name: "AudioViewView",
  components: {
    AudioTool,
  },
  props: ["data", "value", "isActive", "isDataTableField"],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedStyles() {
      const styles = {};

      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }

      inputStyle += ";";
      return inputStyle;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getElementStyle() {
      let borderStyle = "";

      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }

      return borderStyle;
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      showLabel: true,
      inputTextAlignmentValue: "left",
    };
  },
  mounted() {
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
      }
    }

    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
   
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        this.inputTextAlignmentValue = newVal;
      },
      immediate: true, 
    },
  },
};
</script>
  
  <style lang="scss" >
</style>
  
  <style lang="scss" scoped>
</style>